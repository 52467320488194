import React from 'react';

import * as styles from './pressmeddelande.module.scss';
import SEO from '@components/seo';
import Layout from '@components/Layout/Layout';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';
import { Link } from '@mui/material';

type Category = {
  text: string;
  href: string;
};

type Content = {
  title: string;
  subtitle: string;
  identifier: string;
  date: string;
  content: {
    childMarkdownRemark: {
      html: string;
    };
  };
  image: {
    file: {
      url: string;
    };
  };
  tags: string[];
  categories: Category[];
};

const PressmeddelandePage = ({ pageContext: { content } }) => {
  const c = content as Content;
  const img = getImage(c.image);
  const location = useLocation();

  return (
    <Layout>
      <SEO title={c.title} noIndex />
      <div className={styles.container}>
        <div>
          <Link href="/">{'Hem > '}</Link>
          <Link href="/blog">{'Blogg > '}</Link>
          <Link href={location.pathname}>{c.title}</Link>
        </div>
        <h2>{c.title}</h2>
        <GatsbyImage
          style={{
            borderRadius: '5px',
          }}
          image={img}
          alt={c.title}
        />
        <h4>{c.subtitle}</h4>
        <p>{c.date}</p>
        <div
          className={styles.body}
          dangerouslySetInnerHTML={{
            __html: c.content.childMarkdownRemark.html,
          }}
        />
        {c.tags && (
          <div>
            <h4>Tags</h4>
            <div className={styles.tagsContainer}>
              {c.tags.map((tag, index) => (
                <Link
                  key={index}
                  href={`/blog/tags/${tag}`}
                  style={{ marginRight: '5px' }}
                >
                  {tag}
                </Link>
              ))}
            </div>
          </div>
        )}

        {c.categories && (
          <div>
            <h4>Kategorier</h4>
            <div className={styles.categoriesContainer}>
              {c.categories.map((category, index) => (
                <Link
                  key={index}
                  href={`/blog/categories/${category.href}`}
                  style={{ marginRight: '5px' }}
                >
                  {category.text}
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default PressmeddelandePage;
